import React from "react";
import "./about.css";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

function About() {
  return (
    <>
      <Container className="about-us-container">
        <motion.div
          initial={{ x: -100, opacity: 0 }} // Start from the left and hidden
          animate={{ x: 0, opacity: 1 }} // End at the starting position and visible
          transition={{ type: "spring", stiffness: 100, damping: 20 }} // Smooth animation
          className="heading-icon d-flex align-items-center mb-5"
        >
          <h2 className="about-heading">ABOUT US</h2>
          <div className="triangles-right"></div>
        </motion.div>

        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 20,
            delay: 0.2,
          }} // Delay for a staggered effect
        >
          <p className="about-notes">
            Green Mitra has its operations across South Kerala for the past 7
            years. Our vision is to light up your homes the renewable way. With
            the expertise of ours, we together can fulfill our nation's agenda.
            We have on-ground experience in installing solar-based projects on
            site. The sun is a powerful source of sustainable energy that can be
            harnessed thanks to solar panels. We believe in the gift of the sun
            and use that energy to fuel and maintain human activity. Our team is
            an expert in the construction of these devices, which are an
            excellent option to preserve our environment. To help you in your
            renewable way, we are delighted to enhance our green environment.
            There is a real need for reliable backup power in India, but
            somebody has to reach out to the consumer to make the solar
            alternative accessible. Green Mitra intends to make buying and
            operating a solar system easy, affordable, and hassle-free.
          </p>
        </motion.div>
      </Container>
    </>
  );
}

export default About;
