import React from "react";
import "./terms.css";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";

function Terms() {
  return (
    <>
      <Container className="terms">
        {/* Animated Heading Section */}
        <motion.div
          initial={{ x: -100, opacity: 0 }} // Start from the left and hidden
          animate={{ x: 0, opacity: 1 }} // End at the starting position and visible
          transition={{ type: "spring", stiffness: 100, damping: 20 }} // Smooth animation
          className="about-heading-icon d-flex align-items-center"
        >
          <h2 className="terms-heading">TERMS & CONDITIONS</h2>
          <div className="triangular-right"></div>
        </motion.div>

        {/* Animated Text Section */}
        <motion.div
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 20,
            delay: 0.2,
          }} // Delay for staggered effect
        >
          <p className="terms-notes">
            The terms & conditions are part of the proposal and incorporated by
            reference to the proposal. This agreement is between Green Mitra
            Groups & ventures, Trivandrum(here after referred to as company) and
            the customer. Subsidy: Subsidy will be released directly to the bank
            details submitted on the National solar portal by the government
            within the stipulated time after the inspection and approval by the
            DISCOM Payment: Agreed prices are fixed throughout the proposed
            timeframes mentioned in the proposal. Payment terms has been
            mentioned in the earlier page. In the event of delayed payment, the
            company may upon notifying the customer in writing, withhold the
            performance of its obligation until payment has been received.
            Retention of Title: Company reserves the right to ownership of all
            goods delivered by it until all payments including final payment
            have been completed. Bill of materials: Will be as per approved
            detailer project report. Any change in location of the following
            components (Module, inverter, structure, DC/AC distribution boxes,
            path of wiring etc,) after approval of project report may attract
            additional cost and the client will be liable for the same.
            Cancellation: Once an order is placed, it shall be considered
            binding and non-cancellable. The advance payment made inrelation to
            the order is non refundable. Warranty: As per Manufatures warranty
            terms. Free service warrant for 5 years (every six months) by
            company. Limited to Liability: In no event we shall be liable for
            any consequential damages and or losses arising out and connected
            with this supply order in any manner what so ever. Force Majeure: We
            shall be under no liability if performance of contract on our part
            is prevented or delayed whether in whole or in part due to any of
            the causes beyond our reasonable control. The supplier shall not be
            held responsible for any delay, loss or damage arising from any
            cause, matter or thing beyond the supplier's/ sub-supplier's
            reasonable control, including, without prejudice to strikes,
            lockouts, go-slow, delays in approval of drawing, rejection of any
            important part of work, default of sub supplier in supplying the
            parts / raw materials, lack of transportation facilities, inability
            to obtain necessary labor or materials, official prohibition of
            transit of goods, requisition by legal authorities of personal
            property, movable or immovable, restriction in power supply or any
            other action of the government, riots, war or warlike conditions,
            acts of nature or God such as epidemics, floods, earthquakes, fires
            etc. whether such delay or impediment occurs before or after the
            time or extended time for dispatch or completion.In case supply is
            affected by the force majeure conditions, we shall intimate the
            purchaser, within a reasonable ti me, of suchoccurrence and suitable
            extension to the delivery time shall be granted.
          </p>
        </motion.div>
      </Container>
    </>
  );
}

export default Terms;
