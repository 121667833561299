import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import "./contact.css";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:5004/greenmitra/contact-us",
        formData
      );
     toast.success("Your message has been sent successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
      });
    } catch (error) {
      toast.error("Failed to send the message. Please try again.");
    }
  };

  return (
    <Container className="contact-us-container">
      <Row>
        <Col md={6} className="contact-form">
          <div className="heading-icon d-flex align-items-center">
            <h2 className="contact-heading">CONTACT US</h2>
            <div className="triangle-right"></div>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formService">
              <Form.Label>Service</Form.Label>
              <Form.Control
                type="text"
                name="service"
                value={formData.service}
                onChange={handleChange}
                placeholder="Enter the service"
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Your message"
                required
              />
            </Form.Group>

            <Button type="submit" className="submit-btn mb-5 w-100">
              Submit
            </Button>
          </Form>
          {responseMessage && (
            <div className="response-message">{responseMessage}</div>
          )}
        </Col>

        {/* Map Section */}
        <Col md={6} className="map-container">
          <div className="map-wrapper">
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345092546!2d144.95373531531833!3d-37.81627927975144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577c4e7db4dc3de!2sFlinders+Street+Station!5e0!3m2!1sen!2sau!4v1539150507026"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            ></iframe>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default Contact;
