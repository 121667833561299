import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { faFacebook, faTwitter, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../../Assets/Logo.png';
import { faBuilding, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";




function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4}>
            <img className="image" src={logo} alt="" />
          </Col>
          <Col md={4}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/services">Our Services</a></li>
              <li><a href="#projects">Projects</a></li>
              <li><a href="#careers">Careers</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Connect with Us</h5>
            <p> <FontAwesomeIcon className="me-2" icon={faBuilding} />SG Near Temple Kaithamukku, VP/3/335 Near Mutharamman Temple Konchira. RF Building Opp Juma Masjid, Nagaroor.</p>
            <p> <FontAwesomeIcon className="me-2" icon={faBuilding} />First Floor Joseph Thomas Building Near KSRTC Bus Stand, Ayoor.</p>
            <p> <FontAwesomeIcon className="me-2" icon={faEnvelope} />greenmitragroupsventures@gmail.com</p>
            <p><FontAwesomeIcon className="me-2" icon={faPhone} />+916235354646 | +917025354747</p>
            <div className="social-links">
              <a href=" https://www.facebook.com/greenmitrasolar " target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a href="https://www.instagram.com/greenmitrasolar/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mt-4">
          <Col className="text-center">
            <p className="footer-bottom-text">
              &copy; {new Date().getFullYear()} GreenMitra. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
