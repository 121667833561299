import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home/Home';
import Contact from './Pages/Contact/Contact';
import About from './Pages/About/About';
import Terms from './Pages/Terms & conditions/Terms';



// Import other components, like Home or any other page components here

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
      
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about" element={<About/>} />
      
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
