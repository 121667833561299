import { useEffect, useRef } from 'react';
import "./home.css";
import { Card, Row, Col, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import home from "../../Assets/home.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSolarPanel } from "@fortawesome/free-solid-svg-icons";
import { motion, useAnimation } from 'framer-motion';



function Home() {
  const controls = useAnimation();
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start({ x: 0, opacity: 1 });
        }
      },
      { threshold: 0.2 } // Adjust threshold as needed
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }

    return () => {
      if (missionRef.current) {
        observer.unobserve(missionRef.current);
      }
    };
  }, [controls]);

  const cardData = [
    {
      title: "Solar Panels",
      text: "Efficient solar panels that convert sunlight into clean energy.",
      imgSrc:
        "https://cdn.prod.website-files.com/6202984b28c7d29da22ca7b8/65e7735bbf888aea5ec13fc5_Top-Solar-Contractors-OG.png",
    },
    {
      title: "Renewable Energy",
      text: "Harness the power of the sun to reduce carbon emissions.",
      imgSrc:
        "https://green.org/wp-content/uploads/2024/01/Solar-Energy-Career-Opportunities-and-Paths.jpg",
    },
    {
      title: "Solar Inverters",
      text: "Advanced inverters to store solar energy for continuous power.",
      imgSrc: "https://enerpecc.com/wp-content/uploads/2019/03/2.2.jpg",
    },
    {
      title: "Cost Savings",
      text: "Save money by switching to solar energy for your home or business.",
      imgSrc:
        "https://thumbs.dreamstime.com/b/engineer-solar-power-plant-portrait-happy-engineer-protective-helmet-standing-digital-tablet-solar-power-163556589.jpg",
    },
  ];
  return (
    <>
      <div className="row position-relative overlay-container">
        <img src={home} alt="" className="img-fluid overlay-image" />
        <div className="overlay-content">
          <p className="overlay-description">
            <FontAwesomeIcon
              className="me-2"
              icon={faSolarPanel}
              style={{ color: "#26cdd9" }}
            />
            WELCOME TO GREEN MITRA
            <FontAwesomeIcon
              className="solar-icon"
              icon={faSolarPanel}
              style={{ color: "#26cdd9" }}
            />
          </p>
          <p className="overlay-title">
            Taking Your Building Or <br /> Home Off The Grid By Solar
          </p>
        </div>
        <svg
  className="wave-bottom"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 1440 320"
>
  <path
    fill="#66cdaa"
    fillOpacity="1"
    d="M0,224L48,213.3C96,203,192,181,288,192C384,203,480,245,576,250.7C672,256,768,224,864,213.3C960,203,1056,213,1152,213.3C1248,213,1344,203,1392,197.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
  ></path>
</svg>

      </div>
      <div className="row">
        <div className="wavey-bottom d-flex">
          <div className="col-4">
            <h6 className="text-white">ECO-FRIENDLY</h6>

            <p className="text-whites">
              Eco-friendly choices protect our planet.
            </p>
          </div>
          <div className="col-4">
            <h6 className="text-white">LOW MAINTAINANCE</h6>
            <p className="text-whites">
              Eco-friendly choices protect our planet.
            </p>
          </div>
          <div className="col-4">
            <h6 className="text-white">AFFORDABLE PRICE</h6>
            <p className="text-white">
              Eco-friendly choices protect our planet.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="  introductionmain col-md-6">
            <img
              className="introduction img-fluid"
              src="https://media.licdn.com/dms/image/D5612AQGWp3rXWT76fg/article-cover_image-shrink_600_2000/0/1685690588173?e=2147483647&v=beta&t=3_pDuVvP5CvO5U2oXIDvaOOplCXmnSM2dJpaiQ0X_bc"
              alt=""
            />
          </div>

          <div className="col-md-6">
            <div className="heading-icon d-flex align-items-center">
              <h2 className="mission-heading">GREEN MITRA</h2>
              <div className="triangle-rights"></div>
            </div>
          
            <p className="mitra-introduction">
              Green Mitra is a visionary leader in the field of sustainable
              energy, committed to transforming the way we power our lives
              through clean, renewable solar solutions. Our mission is to make
              solar energy accessible and affordable for everyone, from
              residential homes to large-scale commercial enterprises. At Green
              Mitra, we believe in harnessing the limitless power of the sun to
              drive positive change, reduce dependency on fossil fuels, and
              contribute to a healthier, greener planet. We offer
              state-of-the-art solar technology tailored to meet the specific
              energy needs of our customers, ensuring maximum efficiency and
              cost savings.
            </p>
          
          </div>
        </div>
      </div>
    
      <div className="container">
      <motion.div
          ref={missionRef}
          initial={{ x: -100, opacity: 0 }}
          animate={controls}
          transition={{ type: "spring", stiffness: 100, damping: 20, delay: 0.2}}
        >
        <div className="mission">
        
          <div className="heading-icon d-flex align-items">
            <h2 className="mission-heading">OUR MISSION</h2>
            <div class="triangle-rightS"></div>
          </div>
          <p className="mitra-mission mt-5">
            A solar company's mission typically revolves around promoting
            sustainable energy solutions and reducing carbon footprints through
            the use of solar technology. Here’s a concise example: Mission
            Statement: "Our mission is to empower communities and businesses to
            harness the power of the sun, providing innovative and affordable
            solar energy solutions that promote sustainability, reduce energy
            costs, and contribute to a cleaner, greener planet. We strive to
            lead the transition to renewable energy by delivering exceptional
            service, cutting-edge technology, and fostering environmental
            stewardship." Feel free to adjust any part to better fit your
            vision!
          </p>
        </div>
        </motion.div>
        
        {/* CARDS */}
        <Container className="cards">
          <Row xs={2} sm={2} md={2} lg={4} className="g-4 mb-5">
            {cardData.map((card, index) => (
              <Col key={index}>
                <Card className="card-home h-100">
                  <Card.Img
                    className="home-page-cards"
                    variant="top"
                    src={card.imgSrc}
                  />
                  <Card.Body>
                    <h6 className="card-heading">{card.title}</h6>
                    <Card.Text className="card-notes">{card.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Home;
