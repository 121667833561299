import React, { useState, useEffect } from "react";
import logo from "../../Assets/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import "./header.css";
import axios from "axios";

function Header() {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Toggle menu on mobile screens
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Fetch services from API
  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:5004/greenmitra/list/service"
        );
        if (response.data.result) {
          setServices(response.data.list);
        } else {
          setError("Failed to fetch services");
        }
      } catch (err) {
        setError("An error occurred while fetching services");
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  return (
    <nav className="navbar position-absolute navbar-expand-lg head-footer-bg sticky-header">
      <a href="/" className="navbar-brand">
        <img src={logo} alt="logo" className="navbar-brand-img" />
      </a>

      <button
        className="navbar-toggler"
        type="button"
        aria-label="Toggle navigation"
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} className="navbar-toggler-icon" />
      </button>
      <div className="header-headlines container justify-content-center align-item-center d-flex">
        <div
          className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
        >
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <a
                className={`nav-link ${currentPath === "/" ? "active" : ""}`}
                href="/"
              >
                HOME
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/about" ? "active" : ""
                }`}
                href="/about"
              >
                ABOUT
              </a>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  className="nav-link"
                  id="dropdown-services"
                >
                  SERVICES
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  {loading ? (
                    <Dropdown.Item>Loading...</Dropdown.Item>
                  ) : error ? (
                    <Dropdown.Item>{error}</Dropdown.Item>
                  ) : (
                    services.map((service) => (
                      <Dropdown.Item
                        key={service.s_id}
                        className="dropdown-content"
                      >
                        {service.s_name}
                      </Dropdown.Item>
                    ))
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/contact" ? "active" : ""
                }`}
                href="/contact"
              >
                CONTACT US
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/terms" ? "active" : ""
                }`}
                href="/terms"
              >
                TERMS & CONDITIONS
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="mobile-menu w-100">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <a
                className={`nav-link ${currentPath === "/" ? "active" : ""}`}
                href="/"
              >
                HOME
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/about" ? "active" : ""
                }`}
                href="/about"
              >
                ABOUT US
              </a>
            </li>
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle
                  variant="link"
                  className="nav-link"
                  id="dropdown-services-mobile"
                >
                  SERVICES
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  {loading ? (
                    <Dropdown.Item>Loading...</Dropdown.Item>
                  ) : error ? (
                    <Dropdown.Item>{error}</Dropdown.Item>
                  ) : (
                    services.map((service) => (
                      <Dropdown.Item
                        key={service.s_id}
                        href={`/services/${service.s_id}`}
                        className="dropdown-content"
                      >
                        {service.s_name}
                      </Dropdown.Item>
                    ))
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/contact" ? "active" : ""
                }`}
                href="/contact"
              >
                CONTACT US
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  currentPath === "/terms" ? "active" : ""
                }`}
                href="/terms"
              >
                TERMS & CONDITIONS
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Header;
